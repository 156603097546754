import { useRef } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import type { PossibleThumbs, TSlider } from './slider.types';
import useInlineGradient, { defaultColorVariables } from './use-inline-gradient';
import cx from 'classnames';
import ThumbLabels from './thumb-labels';
import { isRtl } from '@dx-ui/utilities-get-language-direction';

/**
 * A slider component that can have multiple thumbs. Each thumb can have a label and a value.
 * @see [Radix UI React Slider](https://www.radix-ui.com/primitives/docs/components/slider)
 */
export const Slider = <T extends PossibleThumbs, U extends T>({
  disabled = false,
  fillIndex = 0,
  hiddenLegend = false,
  inputLabels,
  labelType = 'text',
  language,
  max = 1,
  maxLabel,
  min = 0,
  minLabel,
  offColor = defaultColorVariables.offColor,
  onChange,
  onColor = defaultColorVariables.onColor,
  step = 1,
  thumbLabelFn,
  title,
  values,
  orientation = 'horizontal',
  variant = 'default',
  brandComponentTheme,
}: TSlider<T, U>) => {
  const isRtlLanguage = isRtl(language);
  const isDefault = variant === 'default';
  const isPrimary = variant === 'primary';
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isVertical = orientation === 'vertical';
  const isHorizontal = orientation === 'horizontal';
  const thumbRef = useRef<HTMLSpanElement>(null);

  const valueRatios = values.map((val) => {
    /** set each input's ratio between min and max. always clamped between 0 and 1 */
    let valueRatio = (val - min) / (max - min);
    if (valueRatio < 0) valueRatio = 0;
    else if (valueRatio > 1) valueRatio = 1;
    return valueRatio;
  });

  /** collect fill areas for thumb track. alternates 'on', 'off' */
  const areas = values.map((_, i) => i % 2);
  /** add starting fill index. do we start with 'on' or 'off' */
  const fillAreas: OneLonger<Tuple<U, number>> =
    fillIndex === 1
      ? ([fillIndex, ...areas] as OneLonger<Tuple<U, number>>)
      : ([...areas, fillIndex] as OneLonger<Tuple<U, number>>);
  /** list of gradient values for thu */
  const gradValues = fillAreas.reduce((prev, filled, i) => {
    prev.push([
      filled,
      i === 0 ? 0 : (valueRatios[i - 1] as number) * 100,
      i === fillAreas.length - 1 ? 100 : (valueRatios[i] as number) * 100,
    ]);
    return prev;
  }, [] as Array<Tuple<3, number>>);

  const inlineGradProps = useInlineGradient({
    values: gradValues,
    disabled,
    onColor,
    offColor,
    isRtlLanguage,
    orientation,
    brandComponentTheme,
  });
  const defaultLabelFn = (val: number) => `${val}`;
  const thumbLabels = values.map(thumbLabelFn || defaultLabelFn) as Tuple<U, string>;
  const labels = isRtlLanguage ? thumbLabels.reverse() : thumbLabels;
  const WrapperElement = values.length > 1 ? 'fieldset' : 'div';
  const TitleElement = values.length > 1 ? 'legend' : 'p';

  return (
    <WrapperElement
      className={cx('px-0.5', {
        'text-text-disabled': disabled,
        'h-full py-4': isVertical,
        'mb-8 pt-8': isHorizontal,
      })}
    >
      <TitleElement
        className={cx('label w-full', {
          'mb-4': labelType === 'tooltip',
          'sr-only': hiddenLegend,
        })}
      >
        {title}
      </TitleElement>
      <div
        className={cx({
          'relative z-10': labelType === 'tooltip',
          'h-full': isVertical,
        })}
      >
        <SliderPrimitive.Root
          className={cx('relative flex touch-none select-none', {
            'h-full justify-center': isVertical,
            'h-10 w-full items-center': isHorizontal,
          })}
          dir={isRtlLanguage ? 'rtl' : 'ltr'}
          disabled={disabled}
          max={max}
          min={min}
          minStepsBetweenThumbs={1}
          name={title}
          step={step}
          onValueChange={onChange}
          value={values}
          orientation={orientation}
        >
          <SliderPrimitive.Track
            className={cx('slider-track', {
              'slider-track--vertical': isVertical,
              'slider-track--horizontal': isHorizontal,
            })}
            data-testid="slider-track"
            style={{ ...inlineGradProps }}
          >
            <SliderPrimitive.Range className="slider-range" />
          </SliderPrimitive.Track>
          {values.map((_value, i) => {
            return (
              <SliderPrimitive.Thumb
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={cx('slider-thumb group', {
                  'slider-thumb--horizontal': isHorizontal,
                  'slider-thumb--vertical': isVertical,
                  'slider-thumb--disabled': disabled,
                  'slider-thumb--default': isDefault,
                  'slider-thumb--primary': isPrimary,
                  'slider-thumb--dark': isDark,
                  'slider-thumb--light': isLight,
                })}
                // radix applies this name attribute to the generated input field rendered
                name={`slider-input-${i}`}
                // this id is applied to the span radix renders as the thumb
                id={`slider-thumb-${i}`}
                aria-valuetext={labels[i]}
                asChild
                {...(isHorizontal && { 'aria-labelledby': `slider-thumb-label-${i}` })}
                {...(isVertical && { 'aria-label': inputLabels[i] })}
              >
                <span ref={thumbRef} className="relative block">
                  {labelType === 'tooltip' ? (
                    // Tooltip arrow styles to ensure the arrow follows the tooltip correctly
                    <span
                      className={cx(
                        'relative block size-full',
                        'after:bg-bg after:border-border after:absolute after:-top-4 after:left-[calc(50%_-_6px)] after:inline-block after:size-3 after:rotate-[-135deg] after:border-l after:border-t group-hover:after:-top-[19px] group-focus:after:-top-[19px]'
                      )}
                    />
                  ) : null}
                </span>
              </SliderPrimitive.Thumb>
            );
          })}
          {isHorizontal ? (
            <ThumbLabels
              labels={labels}
              inputLabels={inputLabels}
              valueRatios={valueRatios}
              labelType={labelType}
              isRtlLanguage={isRtlLanguage}
            />
          ) : null}
        </SliderPrimitive.Root>
      </div>
      {minLabel && maxLabel ? (
        <div className="relative flex justify-between">
          <div aria-hidden="true">{minLabel}</div>
          <div aria-hidden="true">{maxLabel}</div>
        </div>
      ) : null}
    </WrapperElement>
  );
};

export default Slider;
