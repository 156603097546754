import { forwardRef, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
// Using a custom useResizeObserver hook due to a label alignment issue with the slider in Safari when the useResizeObserver hook from usehooks-ts is used.
// This is likely happening because of how on initial mount the bounding box size is not recorded.
import { useResizeObserver } from './use-resize-observer';
import cx from 'classnames';
import type { PossibleThumbs } from './slider.types';

type TThumbLabelProps<T extends PossibleThumbs, U extends T> = {
  /** Whether the language is written right to left */
  isRtlLanguage: boolean;
  /** A tuple of labels corresponding to each input value, they are screen reader only labels and add additional context to the visible labels shown */
  inputLabels: Tuple<U, string>;
  /** array of labels */
  labels: string[] | ReactNode[];
  /** Whether the thumb label is displayed as text or a tooltip */
  labelType: 'text' | 'tooltip';
  /** array of normalized positions of label thumbs along the track */
  valueRatios: number[];
};

// the thumb diameter
export const THUMB_W = 28;
// the distance at which labels are joined with a LabelJoin
export const LABEL_JOIN_DISTANCE = 20;
export const JOIN_LABEL_LENGTH = 6;

const LABEL_JOIN_CENTER = LABEL_JOIN_DISTANCE / 2 + JOIN_LABEL_LENGTH / 2;

const tooltipClasses =
  'relative h-max bg-bg text-text-alt text-left text-xs font-normal px-4 py-2.5 w-max border border-border';

const textClasses =
  'absolute inline-block w-20 max-w-max -translate-x-2/4 whitespace-nowrap text-center font-bold';

const calculateLabelPositions = (
  trackRect: DOMRect,
  valueRatios: number[],
  labelRefs: HTMLElement[],
  isRtlLanguage: boolean
) => {
  const labelRects = labelRefs?.map((label) => label.getBoundingClientRect());
  const xCoordinates = labelRects?.map((rect, i) => {
    let x =
      (trackRect?.width - THUMB_W) * (valueRatios[i] as number) + THUMB_W / 2 - rect?.width / 2;
    if (x < trackRect?.left) {
      x = 0;
    } else if (x + rect?.width > trackRect?.right) {
      x = trackRect?.right - rect?.width;
    }
    return isRtlLanguage ? -x : x;
  });

  if (isRtlLanguage) {
    xCoordinates.reverse();
  }

  return { xCoordinates, labelRects };
};

const adjustLabelOverlaps = (
  xCoordinates: number[],
  labelRects: DOMRect[],
  trackRect: DOMRect,
  isRtlLanguage: boolean
) => {
  const joinLabelXCoordinates = Array<number | null>(xCoordinates?.length).fill(null);

  for (let i = 0; i < xCoordinates.length - 1; i++) {
    const overlap =
      (xCoordinates[i] as number) +
      LABEL_JOIN_DISTANCE +
      (labelRects[i] as DOMRect)?.width -
      (xCoordinates[i + 1] as number);
    if (overlap > 0) {
      const limits = isRtlLanguage
        ? {
            left: -(trackRect.right - (labelRects[i] as DOMRect)?.width),
            right: 0,
            track: trackRect?.left,
          }
        : {
            left: 0,
            right: trackRect.right - (labelRects[i + 1] as DOMRect)?.width,
            track: trackRect.right,
          };

      if ((xCoordinates[i] as number) - overlap / 2 < limits?.left) {
        xCoordinates[i] = limits?.left;
        xCoordinates[i + 1] =
          (xCoordinates[i] as number) + (labelRects[i] as DOMRect)?.width + LABEL_JOIN_DISTANCE;
      } else if (
        (xCoordinates[i + 1] as number) + (labelRects[i + 1] as DOMRect)?.width + overlap / 2 >
        limits.track
      ) {
        xCoordinates[i + 1] = limits.right;
        xCoordinates[i] =
          (xCoordinates[i + 1] as number) - (labelRects[i] as DOMRect)?.width - LABEL_JOIN_DISTANCE;
      } else {
        (xCoordinates[i] as number) -= overlap / 2;
        (xCoordinates[i + 1] as number) += overlap / 2;
      }
      joinLabelXCoordinates[i] = isRtlLanguage
        ? (xCoordinates[i] as number) + LABEL_JOIN_CENTER
        : (xCoordinates[i + 1] as number) - LABEL_JOIN_CENTER;
    }
  }

  for (let i = xCoordinates?.length - 1; i > 0; i--) {
    const overlap =
      (xCoordinates[i - 1] as number) +
      (labelRects[i - 1] as DOMRect)?.width +
      LABEL_JOIN_DISTANCE -
      (xCoordinates[i] as number);
    if (overlap > 0) {
      (xCoordinates[i - 1] as number) -= overlap;
      joinLabelXCoordinates[i - 1] = isRtlLanguage
        ? (xCoordinates[i - 1] as number) + LABEL_JOIN_DISTANCE - JOIN_LABEL_LENGTH
        : (xCoordinates[i] as number) - LABEL_JOIN_DISTANCE + JOIN_LABEL_LENGTH;
    }
  }

  if (isRtlLanguage) {
    const overlap = xCoordinates[0] as number;
    const limit = -trackRect.right + (labelRects[labelRects?.length - 1] as DOMRect)?.width;
    if (overlap < limit) {
      xCoordinates[0] = limit;
      for (let i = 0; i < xCoordinates?.length - 1; i++) {
        if (joinLabelXCoordinates[i] !== null) {
          joinLabelXCoordinates[i] =
            (xCoordinates[i] as number) + LABEL_JOIN_DISTANCE - JOIN_LABEL_LENGTH;
          xCoordinates[i + 1] =
            (xCoordinates[i] as number) + (labelRects[i] as DOMRect)?.width + LABEL_JOIN_DISTANCE;
        } else break;
      }
    }
  } else {
    const overlap = -(xCoordinates[0] as number);
    if (overlap > 0) {
      (xCoordinates[0] as number) += overlap;
      for (let i = 0; i < xCoordinates?.length - 1; i++) {
        if (joinLabelXCoordinates[i] !== null) {
          joinLabelXCoordinates[i] = (joinLabelXCoordinates[i] as number) + overlap;
          (xCoordinates[i + 1] as number) += overlap;
        } else break;
      }
    }
  }

  return joinLabelXCoordinates;
};

type LabelProps = {
  /** Screenreader only label to give additional context to the visible labels */
  additionalLabel?: string;
  id: string;
  /** Label content */
  label: string | ReactNode;
  /** The horizontal position for the label center correctly over the thumb */
  labelPosition?: number;
  /** Whether the label is shown as text or in a tooltip */
  labelType: string;
};

function getTooltipTopOffset({ tooltipRect }: { tooltipRect?: DOMRect }) {
  return tooltipRect ? tooltipRect?.height + 2 : 0;
}

const Label = forwardRef<HTMLSpanElement, LabelProps>(
  ({ additionalLabel, id, label, labelPosition, labelType }, forwardedRef) => {
    return (
      <span
        id={id}
        key={id}
        ref={forwardedRef}
        className={labelType === 'tooltip' ? tooltipClasses : textClasses}
        style={{ transform: `translateX(${labelPosition}px` }}
      >
        <span aria-hidden="true">{label}</span>
        <span className="sr-only">{additionalLabel}</span>
      </span>
    );
  }
);

Label.displayName = 'Label';

/**
 * smart positioned text labels that position themselves as close to centered over their corresponding thumb positions
 * as possible. valueRatios[] are normalized thumb positions from 0 to 1. A layout effect and resize observer hook is used to prevent overlaps
 * @todo the layout effect is O(n) and could possibly be improved, and definitely cleaned up a bit
 */
const ThumbLabels = <T extends PossibleThumbs, U extends T>({
  inputLabels,
  isRtlLanguage,
  labels,
  labelType = 'text',
  valueRatios,
}: TThumbLabelProps<T, U>) => {
  const [trackRef, trackRect] = useResizeObserver();
  const [labelsX, setLabelsX] = useState<number[]>([]);
  const labelRefs = useRef<HTMLElement[]>([]).current;
  const [labelElementRects, setLabelRects] = useState<DOMRect[]>([]);
  const [joinLabelXCoordinates, setJoinLabelXCoordinates] = useState<Array<number | null>>([]);
  const [topOffsetState, setTopOffset] = useState<number>(0);

  useIsomorphicLayoutEffect(() => {
    const { xCoordinates, labelRects } = calculateLabelPositions(
      trackRect,
      valueRatios,
      labelRefs,
      isRtlLanguage
    );
    setLabelRects(labelRects);
    const topOffset = getTooltipTopOffset({
      tooltipRect: labelElementRects[0],
    });
    setTopOffset(topOffset);
    const joinLabelXCoordinates = adjustLabelOverlaps(
      xCoordinates,
      labelRects,
      trackRect,
      isRtlLanguage
    );
    setJoinLabelXCoordinates(joinLabelXCoordinates);
    setLabelsX(xCoordinates);
  }, [trackRect, valueRatios, isRtlLanguage, topOffsetState, setTopOffset]);

  return (
    <span
      ref={trackRef}
      className={cx('absolute flex size-full justify-between', {
        '-top-5': labelType === 'text',
        '-z-1': labelType === 'tooltip',
      })}
      style={labelType === 'tooltip' ? { top: `-${topOffsetState}px` } : {}}
    >
      {labels?.map((label: ReactNode, i: number) => {
        return (
          <Label
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            id={`slider-thumb-label-${i}`}
            label={label}
            ref={(ref) => {
              if (ref) {
                // eslint-disable-next-line react-compiler/react-compiler
                labelRefs[i] = ref;
              }
            }}
            labelPosition={labelsX[i]}
            labelType={labelType}
            additionalLabel={inputLabels[i]}
          />
        );
      })}
      {joinLabelXCoordinates
        .filter((value: number | null) => value !== null)
        .map((value: number | null, i: number) => (
          <span
            className="text-text-alt absolute inline-block text-center before:content-['-']"
            aria-hidden="true"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{
              transform: `translateX(${value}px)`,
            }}
          />
        ))}
    </span>
  );
};

export default ThumbLabels;
